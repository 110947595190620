import commonConfig from "./common.config";

export default {
  ...commonConfig,
  onecarePushkey: 'BM3MxIRu28xJIcIs76BlsH7cPM17pBZwMMAcvq1gQMGgaDnA3PzZ5vgndhNtoFQPIon__LFTQqRF80Z6AHNjGzs',
  vikandDirectPushkey: 'BCC9TlmesdLmBIYUUYhhu8Df0angc2g6tf8zY9BlUnvQavMYrrAp9ZnsKz_UzQ-aC1_EOEtg4JQMqjNPuKc-t-g',
  onshipPushkey: 'BPpdtKdM-aTcVL4i2Z5ccHeOnLJbZaozItAv2UR0_C08r_kLVswJVo5W1HYat3Kl58PLtRgwhSEcEB8Fubv2T2M',
  onshipWebPushAppType: "onship",
  vikandWebPushAppType: "vikand",
  onecareWebPushAppType: "onecare",
  licenseCodeVerificationAPI: 'https://w6k3jxe1yi.execute-api.us-east-1.amazonaws.com/stage',
  licenseCodeVerificationAPI_Token: '2Q5WfwK4lp9WaHgHa7IFR6fUSAntswKdazEPCNhW',
};
